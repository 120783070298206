@use 'shared-mixin' as mx;

/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/default.theme.css";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/accordion";

@import 'quill/dist/quill.bubble.css';
@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.core.css";

:root {
  --primary: #FEF64E;
  --secondary: #000000;
  --danger: #e73f1b;
  --success: #69C67D;
  --beige: #EEE1C6;
  --bs-primary: var(--primary);
  --primary-light: #FDF9A1;

  --grey-1: rgba(192, 192, 192, 1);
  --grey-2: rgba(102, 102, 102, 1);
  --grey-3: rgba(235, 235, 235, 1);
  --grey-4: rgba(233, 233, 228, 1);
  --grey-5: rgba(240, 240, 240, 1);
  --grey-6: rgba(250, 250, 250, 1);
  --grey-7: rgba(195, 195, 195, 0.9);
  --p-dark: rgba(170, 169, 37, 1);
  --h-dark: rgba(0, 0, 0, 0.9);
  --fail: rgba(236, 28, 36, 1);
  --correct: rgba(105, 198, 125, 0.3);

  --bs-primary-rgb: rgb(244, 206, 51);
  --bs-secondary-rgb: rgba(0, 0, 0, 1);
  --bs-secondary: $secondary;
  --bs-danger: $danger;
  --bs-success: $success;
  --bs-beige: $beige;

  // TODO: Add "Satoshi" font
  --bs-font-sans-serif: "Roboto", sans-serif;

  --background-color: white;
  --background-secondary: #ebebeb;

  --SIZE_SM: 24px;
  --SIZE_MD: 36px;
  --SIZE_LG: 48px;
  --SIZE_XL: 96px;
  --SIZE_XXL: 192px;

  //  NOTE: Overwrite this prop in global app styles if you are using wh-s-nav-top component
  --size-nav-top-height: 0;

  --timeline-item-height: 8rem;
  --timeline-item-margin: 1rem;

  --main-content-max-width-md: 1300px;
  --main-content-max-width-sm: 1080px;

  /* ****************************************** *
   * ****************************************** *
   * ********  CARD
   * ****************************************** *
   * ****************************************** */
  --card-background: white;
  --card-border-radious: 1rem;

  --card-inner-border-radious: 0.7rem;

  /* ****************************************** *
   * ****************************************** *
   * ********  INPUTS & FORMS
   * ****************************************** *
   * ****************************************** */
  --input-height-md: 45.55px;
  --input-height-lg: 52px;
  --input-label-font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --input-border: 1px solid #C0C0C0;
  --input-border-hover: solid black 1px;
  --input-shadow: 2px 2px 4px rgb(168 168 168 / 25%);
  --input-focus-color: #095AFE;
  --input-focus-outline: var(--input-focus-color) solid 2px;
  --input-label-background-color: white; // Background color of label which covers outline while user focuses input
  --input-line-height-md: 5.5ex; // Medium variant of input
  --input-line-height-lg: 7ex; // Large variant of input
  --input-feedback-message-box-height: 1.5rem; // Size of the box where error or info label is located

  --form-element-bottom-margin: 1.5rem;
  --form-double-element-width: 48%;

  /* ****************************************** *
   * ****************************************** *
   * ********  PANEL TOP
   * ****************************************** *
   * ****************************************** */
  --panel-top-height: 5rem;
  --panel-top-width: 100%;

  /* ****************************************** *
   * ****************************************** *
   * ********  SIDE NAV
   * ****************************************** *
   * ****************************************** */
  --side-nav-height: 100%;
  --side-nav-width-open: 13rem;
  --side-nav-width-closed: 6.5rem;
  --side-nav-item-active-background-color: var(--primary); // OLD: #EFEFEF
  --side-nav-item-hover-background-color: #EFEFEF;
  --side-nav-default-border-thickness: 1px;
  --side-nav-default-border: var(--side-nav-default-border-thickness) solid gainsboro;

  /* ****************************************** *
   * ****************************************** *
   * ********  SCREEN SIZE
   * ****************************************** *
   * ****************************************** */

  //  //
  //  Used for df-screen function
  //  //
  --df-max-screen-mobile-small: 380;
  --df-max-screen-mobile: 450;
  --df-max-screen-tablet-portrait: 600;
  --df-max-screen-tablet-landscape: 1200;
  --df-max-screen-desktop: 1799;
  --df-min-screen-desktop-large: 1800;

  //  //
  //  Used for mf-screen function
  //  //
  --mf-min-screen-mobile-small: 380;
  --mf-min-screen-mobile-small-height: 650;
  --mf-min-screen-mobile-landscape: 650;
  --mf-min-screen-mobile-landscape-height: 375;
  --mf-min-screen-tablet-portrait: 600;
  --mf-min-screen-tablet-landscape: 900;
  --mf-min-screen-desktop: 1100;
  --mf-min-screen-desktop-large: 1800;


  /* ****************************************** *
   * ****************************************** *
   * ********  MAIN LAYOUT
   * ****************************************** *
   * ****************************************** */
  --main-page-layout-max-width: 1300px;

  /* ****************************************** *
   * ****************************************** *
   * ********  PROFILE LAYOUT
   * ****************************************** *
   * ****************************************** */
  --profile-header-continer-size: 33rem;
}

@include mx.df-screen(tablet-portrait) {
  :root {
    --side-nav-width-open: 100%;
    --side-nav-width-closed: 0%;
  }
}

@include mx.df-screen(mobile) {
  :root {
    --side-nav-width-open: 100%;
    --side-nav-width-closed: 0%;
  }
}

@include mx.df-screen(mobile-small) {
  //  Handle Galaxy Fold & other very small devices
  :root {
    --timeline-item-height: 17rem;
    --side-nav-width-open: 100%;
    --side-nav-width-closed: 0%;
  }
}


$primary: var(--primary);
$secondary: var(--secondary);
$danger: var(--danger);
$success: var(--success);
$beige: var(--beige);
$grey-1: var(--grey-1);
$grey-2: var(--grey-2);
$grey-3: var(--grey-3);
$p-dark: var(--p-dark);
$h-dark: var(--h-dark);
$fail: var(--fail);
$correct: var(--correct);

$background-color: var(--background-color);

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: unset;
  font-size: smaller;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0;
}
